/* eslint-disable func-names */
import {
  call,
  select,
  all,
  put,
  takeLatest,
  take,
  takeLeading,
  fork,
  getContext,
  cancel,
  join,
  race,
} from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { read, utils, writeFile } from 'xlsx';
import * as _ from 'lodash-es';
import ExcelJS from 'exceljs';
import { DateTime } from 'luxon';
import { getFileIdentifier, generateStringHash } from 'cosmos-core';
import { propertyType, valuesetSource } from 'cosmos-config/generator';
import { trimUINumber, uuidv4 } from 'cosmos-config/utils';

import {
  setCurrentSchema,
  setImportComplete,
  setImportData,
  setIsUnzipping,
  updateImportStats,
} from '../../Actions/import';
import {
  setRepositoryLoading,
  selectDocumentsIdentifiers,
} from '../../Actions/repository';
import { notify } from '../../Actions/ui';
import { parseResourceId } from '../../Utils';
import callApi from '../Effects/callApi';
import repositoryApi from '../../Api/repository';
import * as actions from '../../Actions/types';
import { parseGatewayResources } from '../../Utils/documentUtils';
import {
  addUploadedDocumentId,
  setUploadCheckLoading,
  setUploadFiles,
  setUploadLoading,
  updateFileHash,
} from '../../Actions/upload';
import {
  flatMapArchives,
  generateProperFilePath,
  generateUniqueFileLabel,
  getFileSize,
  parseFilesToResources,
} from '../../Utils/fileUtils';
import importIssueType from '../../Constants/importIssueType';
import {
  getCurrentSchema,
  getImportColumnMapping,
  getImportStats,
  getImportValueMapping,
} from '../../Selectors/import';
import uploadStatistic from '../../Constants/uploadStatistic';
import importActivity from '../../Constants/importActivity';
import callBatch from '../Effects/callBatch';
import complete from '../Effects/complete';
import { createDataSheet, exportData } from './exportExcelSaga';
import duplicatesCheckerSaga from './duplicatesCheckerSaga';
import { createContentHashSagas } from './contentHashSaga';
import { resourceToGateway, resourceToProperties } from '.';
import { updateDocumentsFilename } from './utils.js';

const uploadMaxFileSize =
  import.meta.env.VITE_APP_UPLOAD_MAX_FILE_SIZE || 10 * 1024 * 1024 * 1024; // 10GB
const uploadMaxFileSizePretty = getFileSize(uploadMaxFileSize).prettySize;

const clearSpecialChars = (input) =>
  input != null ? String(input).replace(/[^0-9a-zA-Z]+/g, '') : null;

function* generateDataSchema(
  data,
  properties,
  columnMapping = {},
  valueMapping = {}
) {
  const docareaService = yield getContext('docareaService');
  const valuesetsMap = yield select(docareaService.getValuesetsMap);

  const projectService = yield getContext('projectService');
  const projectMembers = yield call(projectService.getMembers);

  const headerNames = _.isEmpty(data)
    ? _.chain(properties)
        .filter((p) => p.editable || p.name === '_path')
        .map('name')
        .uniq()
        .value()
    : _.chain(data)
        .flatMap(_.keysIn)
        .map((key) => key.replace(/(\s?\*)$/g, ''))
        .uniq()
        .value();

  const schema = _.chain(properties)
    .filter(
      (p) =>
        headerNames.includes(p.name) ||
        headerNames.includes(p.label) ||
        Object.values(columnMapping).includes(p.name)
    )
    .mapKeys('name')
    .mapValues((p) => {
      let ps = _.chain(p)
        .pick([
          'type',
          'valuesetName',
          'valuesetSource',
          'multiple',
          'groupNames',
          'label',
          'required',
          'conditional',
        ])
        .omit(['conditional'])
        .value();

      ps = {
        ...ps,
        required: ps.required && !ps.conditional,
      };

      if (ps.type === propertyType.SELECT) {
        const propertyValueMapping = valueMapping[p.name] || {};

        const optionsMap = _.chain(valuesetsMap[ps.valuesetName])
          .keyBy('value')
          .mapValues((option) => {
            const mappedLabels = propertyValueMapping[option.value] || [];
            return _.chain(option)
              .pick(['label'])
              .valuesIn()
              .concat(mappedLabels)
              .filter((i) => typeof i === 'string' && i?.trim() !== '')
              .map((i) => clearSpecialChars(i))
              .map((i) => i.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
              .map((i) => `^${i}$`)
              .join('|')
              .value();
          })
          .value();

        return {
          ...ps,
          options: optionsMap,
        };
      }

      if (ps.type === propertyType.MEMBER_SELECT) {
        return {
          ...ps,
          options: _.chain(projectMembers)
            .pickBy(
              (value, key) =>
                _.chain(p.groupNames).isEmpty() || p.groupNames.includes(key)
            )
            .valuesIn()
            .flatMap()
            .filter((u) => !u.group)
            .uniqBy('principalId')
            .keyBy('principalId')
            .mapValues((u) =>
              [u.formattedName, trimUINumber(u.commonname), u.email, u.name]
                .map((i) => clearSpecialChars(i))
                .filter((i) => i != null)
                .map((i) => i.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
                .join('|')
            )
            .value(),
        };
      }

      return ps;
    })
    .value();

  const schemaLabels = Object.values(schema).map((s) => s.label);
  const issuedColumns = headerNames.filter(
    (column) =>
      !(
        Object.keys(schema).includes(column) ||
        schemaLabels.includes(column) ||
        Object.keys(schema).includes(columnMapping[column]) ||
        column === '_path'
      )
  );

  yield put(setCurrentSchema(schema));

  return {
    schema,
    issues: issuedColumns.map((columnName) => {
      const newIssue = {
        rows: [],
        type: importIssueType.COLUMN_UNMATCHED,
        columnName,
        resolvable: true,
      };

      newIssue.contentHash = generateStringHash(JSON.stringify(newIssue));
      return newIssue;
    }),
  };
}

function parseFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array', cellDates: true });

      const sheet = Object.values(workbook.Sheets)[0];
      const res = utils.sheet_to_json(sheet);
      resolve(res);
    };

    reader.readAsArrayBuffer(file);
  });
}

function parseData(data, schema, add = {}) {
  return data
    .map((fldr, idx) => {
      let issues = [];

      const resource = _.reduce(
        fldr,
        (acc, value, key) => {
          const issue = (issueType, value) => {
            const newIssue = {
              idx,
              name: key,
              type: issueType,
              v: value,
            };

            newIssue.contentHash = generateStringHash(JSON.stringify(newIssue));
            issues = [...issues, newIssue];
          };

          const ps = schema[key];

          if (ps == null) {
            return acc;
          }

          if (
            value == null ||
            value === '' ||
            (Array.isArray(value) && value.length === 0)
          ) {
            if (ps.required) {
              issue(importIssueType.REQUIRED);
            }

            return acc;
          }

          const { options, type, multiple } = ps;
          const keys = _.keysIn(options);
          const isNumeric = (k) => /^\d+$/.test(k);
          const numericKey = _.every(keys, isNumeric);

          const getParseValue = (v) => {
            const result = _.chain(options)
              .findKey((expression) =>
                RegExp(expression, 'gi').test(clearSpecialChars(v))
              )
              .value();

            if (result == null) {
              issue(importIssueType.MISSING, _.trim(v));
            }

            if (numericKey) {
              return parseInt(result, 10);
            }

            return result;
          };

          let vl = value;
          if (multiple && typeof value === 'string') {
            vl = value.split('+').map((v) => String(v).trim());
          }

          if (
            type === propertyType.SELECT ||
            type === propertyType.MEMBER_SELECT ||
            type === propertyType.RADIO
          ) {
            return {
              ...acc,
              [key]: Array.isArray(vl)
                ? _.chain(vl)
                    .map((v) => getParseValue(v))
                    .compact()
                    .value()
                : [getParseValue(vl)],
            };
          }

          if (type === propertyType.NUMERIC) {
            try {
              return {
                ...acc,
                [key]: parseInt(vl, 10),
              };
            } catch (err) {
              issue(importIssueType.DATA_TYPE_MALFORMED);
              return acc;
            }
          }

          if (type === propertyType.DATE) {
            if (_.isFinite(+vl) && +vl > 0) {
              return {
                ...acc,
                [key]: +vl,
              };
            } else {
              let dateTime = null;
              if (_.sumBy(vl, (x) => x === '-') === 2) {
                dateTime = DateTime.fromISO(vl);
              } else if (_.sumBy(vl, (x) => x === '/') === 2) {
                dateTime = DateTime.fromFormat(vl, 'MM/dd/yyyy');
              } else if (_.sumBy(vl, (x) => x === '.') === 2) {
                dateTime = DateTime.fromFormat(vl, 'dd.MM.yyyy');
              } else {
                dateTime = DateTime.fromMillis(Date.parse(vl));
              }

              if (dateTime != null && dateTime.isValid) {
                return {
                  ...acc,
                  [key]: dateTime.toMillis(),
                };
              } else {
                issue(importIssueType.DATA_TYPE_MALFORMED);
                return acc;
              }
            }
          }

          if (type === propertyType.YESNO || type === propertyType.CHECKBOX) {
            return {
              ...acc,
              [key]: ['yes', 'true', 'y'].includes(_.toLower(vl)),
            };
          }

          if (type === propertyType.NUMERIC) {
            return {
              ...acc,
              [key]: vl != null ? String(vl) : null,
            };
          }

          if (
            type === propertyType.RICH_TEXTAREA ||
            type === propertyType.SUGGEST ||
            type === propertyType.TEXT ||
            type === propertyType.TEXTAREA
          ) {
            return {
              ...acc,
              [key]: vl != null ? _.trim(vl) : null,
            };
          }

          return {
            ...acc,
            [key]: vl,
          };
        },
        add
      );

      return {
        resource: {
          ...resource,
          _rowid: idx,
        },
        issues,
      };
    })
    .reduce(
      (acc, cur) => ({
        ...acc,
        resources: [...acc.resources, cur.resource],
        issues: [...acc.issues, ...cur.issues],
      }),
      { resources: [], issues: [] }
    );
}

const groupIssues = (issues, schema) => {
  return _.chain(issues)
    .groupBy('type')
    .reduce((typeAcc, issuesByType, issueType) => {
      return [
        ...typeAcc,
        ..._.chain(issuesByType)
          .groupBy('name')
          .reduce((nameAcc, issuesByName, propertyName) => {
            const ps = schema[propertyName];
            return [
              ...nameAcc,
              ..._.chain(issuesByName)
                .groupBy('v')
                .map((issue, value) => {
                  const newIssue = {
                    propertyName,
                    propertyLabel: ps?.label || propertyName,
                    insertable:
                      ps?.valuesetSource === valuesetSource.CUSTOM_VALUESET,
                    resolvable:
                      ps?.valuesetSource === valuesetSource.CUSTOM_VALUESET,
                    valuesetName: ps?.valuesetName,
                    value,
                    rows: _.chain(issue).map('idx').value(),
                    type: issueType,
                  };

                  newIssue.contentHash = generateStringHash(
                    JSON.stringify(newIssue)
                  );
                  return newIssue;
                })
                .value(),
            ];
          }, [])
          .value(),
      ];
    }, [])
    .value();
};

function* importFoldersExcel(folderId, importAction) {
  const { foldertype, files } = importAction;
  let data = yield all(Array.from(files).map((file) => call(parseFile, file)));
  data = data.flatMap((x) => x);

  if (data.length > 0) {
    yield complete(importAction);

    const projectService = yield getContext('projectService');
    const folderProperties = yield call(projectService.getFolderProperties);

    const updatableProperties = _.filter(
      folderProperties,
      (p) => p.editable || p.systemUpdatable
    );

    while (true) {
      const columnMapping = yield select(getImportColumnMapping);
      const valueMapping = yield select(getImportValueMapping);

      const { schema } = yield call(
        generateDataSchema,
        data,
        updatableProperties,
        columnMapping,
        valueMapping
      );

      const translatedData = translatePropertyLabels(
        data,
        schema,
        columnMapping
      );

      const { resources, issues } = parseData(translatedData, schema, {
        foldertype,
      });
      const groupedIssues = groupIssues(issues, schema);

      yield put(setImportData(resources, groupedIssues));

      const action = yield take([
        actions.repositoryImport.EXECUTE_IMPORT,
        actions.repositoryImport.CANCEL_IMPORT,
        actions.repositoryImport.RELOAD_IMPORT,
      ]);

      const { type } = action;

      if (type === actions.repositoryImport.CANCEL_IMPORT) {
        break;
      }

      if (type === actions.repositoryImport.EXECUTE_IMPORT) {
        try {
          yield put(
            setRepositoryLoading(true, 'Importing folders into repository')
          );

          const docareaService = yield getContext('docareaService');
          const valuesetsMap = yield select(docareaService.getValuesetsMap);

          const resourcesToImport = resources.map((res) => {
            return resourceToGateway(res, updatableProperties, valuesetsMap);
          });

          const resourceIds = yield callApi(
            repositoryApi.importResources,
            folderId,
            resourcesToImport
          );

          yield put(
            selectDocumentsIdentifiers(
              resourceIds.map((id) => {
                const { identifier } = parseResourceId(id);
                return identifier;
              })
            )
          );

          yield put(notify('Import was successfull!'));

          yield complete(action);
        } catch (err) {
          console.error(err);
          yield put(
            notify(
              `There was an error while importing folders, reason: ${err.message}`,
              'system-error'
            )
          );
        } finally {
          yield put(setRepositoryLoading(false));
          yield put(setImportComplete([]));
        }
      }
    }
  }
}

async function matchFilesToResources(resources, files, options = {}) {
  const subfolderPropertyName = options.subfolderPropertyName;
  const subfolders = options.subfolders || [];
  const createStubs = options.createStubs || false;
  const fileNameResolve = options.fileNameResolve || ['filename'];
  const multiContent = options.multiContent || false;

  const parsedFiles = files.map((file) => {
    let properName = _.chain(file.name).split('/').last();

    if (multiContent) {
      properName = properName.replace(/\s\(\d\)\.(.*)/, '.$1');
    }

    // instead of creating a new file, put the file in a new object and add the additional properties
    // to keep the webkitRelativePath
    return {
      file,
      name: properName.value(),
    };
  });

  const sanitizeMatchingValue = _.flow([_.toLower, _.trim, _.deburr]);

  const subfoldersMap = _.chain(subfolders)
    .keyBy((folder) => sanitizeMatchingValue(folder[subfolderPropertyName]))
    .mapValues('id')
    .value();

  const importData = resources
    .map((resource) => {
      const expression = new RegExp(
        `^${fileNameResolve
          .map((name) => {
            const fileName = resource[name];
            if (fileName != null) {
              return String(fileName).replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            }
          })
          .filter((x) => x != null)
          .join('[\\s-]')}(\\.[a-z]{2,4})?$`,
        'i'
      );

      const importItem = {
        _rowid: resource._rowid,
        resource,
        files: parsedFiles.filter((pF) => {
          return (
            expression.test(pF.name) && pF.file.path === (resource._path ?? '')
          );
        }),
      };

      if (subfolderPropertyName != null) {
        const identifier = sanitizeMatchingValue(
          resource[subfolderPropertyName]
        );
        const folderId = subfoldersMap[identifier];

        return {
          ...importItem,
          folderId,
          _value: resource[subfolderPropertyName],
        };
      }

      return importItem;
    })
    .filter(
      (r) => (Array.isArray(r.files) && r.files.length > 0) || createStubs
    );

  const resolveFileName = (resource) =>
    fileNameResolve.map((propName) => resource[propName]).join('-');

  const fileMissingIssues = _.chain(resources)
    .xorBy(importData, '_rowid')
    .map((res) => {
      const newIssue = {
        rows: [parseInt(res._rowid, 10)],
        type: importIssueType.FILE_MISSING_FILESET,
        fileName: resolveFileName(res),
      };
      newIssue.contentHash = generateStringHash(JSON.stringify(newIssue));

      return newIssue;
    })
    .value();

  const unmatchedParentIssues = _.chain(importData)
    .filter(
      (resource) => subfolderPropertyName != null && resource.folderId == null
    )
    .groupBy('_value')
    .map((resources, value) => {
      const newIssue = {
        rows: resources.map((r) => parseInt(r._rowid, 10)),
        type: importIssueType.PARENT_UNMATCHED,
        value,
      };
      newIssue.contentHash = generateStringHash(JSON.stringify(newIssue));
      return newIssue;
    })
    .value();

  return Promise.resolve({
    importData,
    issues: _.concat(fileMissingIssues, unmatchedParentIssues),
  });
}

function* fetchSubfolders(folderId, subfolderPropertyName, subfolderType) {
  if (subfolderPropertyName != null) {
    try {
      const { items } = yield call(
        repositoryApi.getFolders,
        folderId,
        subfolderType,
        [subfolderPropertyName]
      );

      const projectService = yield getContext('projectService');
      const folderProperties = yield call(projectService.getFolderProperties);

      return parseGatewayResources(items, folderProperties);
    } catch (err) {
      console.error(err);
    }
  }

  return [];
}

function* importDocument(folderId, resource, itemFiles) {
  const fileHashMap = yield select((state) => state.upload.fileHashMap);
  const hashes = itemFiles
    .map((iF) => {
      const fileId = getFileIdentifier(iF.file);
      return fileHashMap[fileId]?.hash;
    })
    .filter((hash) => !!hash);

  const projectService = yield getContext('projectService');
  const propertiesMap = yield call(projectService.getProperties);
  const docareaService = yield getContext('docareaService');
  const valuesetsMap = yield select(docareaService.getValuesetsMap);

  const properties = resourceToProperties(
    {
      ...resource,
      // only add itemhash if fileHashMap is not an empty object and hashes are not empty
      itemhash:
        _.isEmpty(fileHashMap) || _.isEmpty(hashes) ? [] : _.compact(hashes),
    },
    propertiesMap,
    valuesetsMap
  );

  const importId = uuidv4();
  let resourceId, error, message;
  const totalSize = _.sumBy(
    itemFiles.map((iF) => iF.file),
    'size'
  );
  const startMillis = DateTime.now().toMillis();

  const uploadProgressChannel = yield channel();
  const uploadProgressTask = yield fork(function* (chan) {
    while (true) {
      const action = yield take(chan);
      const { loaded } = action.payload;

      yield put(
        updateImportStats(importId, {
          fileName: resource.filename,
          filePath: resource._path || '',
          progress: Math.round((loaded / totalSize) * 100),
          startMillis,
        })
      );
    }
  }, uploadProgressChannel);

  try {
    resourceId = yield callApi(
      repositoryApi.createDocument,
      folderId,
      _.compact(
        itemFiles.map(
          (iF) => new File([iF.file], iF.name, { type: iF.file.type })
        )
      ),
      properties,
      (loaded) =>
        uploadProgressChannel.put({
          type: 'upload/setProgress',
          payload: {
            loaded,
          },
        })
    );
  } catch (err) {
    console.error(err);
    error = true;
    message = err.message;
  }

  yield cancel(uploadProgressTask);

  const time = DateTime.now().toMillis() - startMillis;

  const result = {
    id: importId,
    resourceId,
    error: !!error,
    fileName: resource.filename,
    filePath: resource._path || '',
    displayName: resource.displayname,
    message,
    time,
    size: totalSize,
    complete: true,
    progress: 100,
    startMillis,
    endMillis: startMillis + time,
  };

  yield put(updateImportStats(importId, result));

  return result;
}

const translatePropertyLabels = (data, schema, mapping = {}) => {
  const labelsMap = _.chain(schema).mapValues('label').invert().value();

  return data.map((item) => {
    return _.mapKeys(item, (value, key) => {
      if (mapping[key] != null) {
        return mapping[key];
      }

      const sanKey = key.replace(/(\s?\*)$/g, '');
      if (labelsMap[sanKey] != null) {
        return labelsMap[sanKey];
      }

      return key;
    });
  });
};

function* generateExport(properties, columns = [], data = []) {
  const { schema } = yield call(
    generateDataSchema,
    [],
    // this could be put into generateDataSchema
    _.chain(properties).concat({ name: '_path', label: 'Path' }).value()
  );

  const workbook = new ExcelJS.Workbook();

  const importDataWorksheet = workbook.addWorksheet('import_data');

  importDataWorksheet.properties.defaultColWidth = 20;

  const toCol = (key, header, required) => {
    if (key == null) {
      return null;
    }

    return {
      key,
      header: required ? `${header || key} *` : header || key,
    };
  };

  importDataWorksheet.columns = _.chain(columns)
    .map((label) => toCol(label, label, true))
    .concat(
      _.entriesIn(schema).map(([key, prop]) =>
        toCol(key, prop.label, prop.required)
      )
    )
    .reject(_.isNull)
    .value();

  importDataWorksheet.getRow(1).font = {
    bold: true,
  };

  importDataWorksheet.addRows(
    _.range(data.length || 100).map((idx) => data[idx] || [])
  );

  const valuesetsSheet = workbook.addWorksheet('valuesets');

  const selectableFieldTypes = [propertyType.SELECT, propertyType.RADIO];
  const mappedSchema = _.mapValues(schema, (v, key) => ({
    ...v,
    name: key,
  }));
  const valuesArray = _.valuesIn(mappedSchema);
  const selectableProps = valuesArray.filter((p) =>
    selectableFieldTypes.includes(p.type)
  );

  valuesetsSheet.columns = selectableProps.map((p) => toCol(p.valuesetName));

  const docareaService = yield getContext('docareaService');
  const valuesetsMap = yield select(docareaService.getValuesetsMap);

  selectableProps.forEach((p) => {
    const c = valuesetsSheet.getColumn(p.valuesetName);
    const options = _.chain(valuesetsMap[p.valuesetName]).map('label').value();

    c.values = [p.valuesetName, ...options];

    const adressRange = `valuesets!$${c.letter}$2:$${c.letter}$${
      Object.keys(options).length + 1
    }`;

    importDataWorksheet
      .getColumn(p.name)
      .eachCell({ includeEmpty: true }, (cell, idx) => {
        if (idx > 1) {
          cell.dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: [adressRange],
            showErrorMessage: true,
          };
        }
      });
  });

  const buffer = yield call([workbook.xlsx, workbook.xlsx.writeBuffer]);

  const blob = new Blob([buffer]);
  return URL.createObjectURL(blob);
}

function* exportFoldersTemplate(folderId, action) {
  const { folderType } = action;
  const projectService = yield getContext('projectService');
  const folderProperties = yield call(projectService.getFolderProperties);
  const project = yield call(projectService.getOpenedProject);

  const importableProperties = folderProperties.filter(
    (p) => p.editable && p.importable
  );

  if (importableProperties.length === 0) {
    yield put(
      notify(
        'There are not any properties to be included in the template. Please reach your Cosmos contact to resolve the problem.',
        'user-error'
      )
    );

    return;
  }

  const objectUrl = yield call(generateExport, importableProperties);

  const fileName = `${
    project?.name
  }_${DateTime.now().toSQLDate()}_import_${folderType}_template.xlsx`;

  yield complete(action, { objectUrl, fileName });
}
function* exportDocumentsTemplate(folderId, documentFiles, action) {
  const { subfolderPropertyName } = action;
  const projectService = yield getContext('projectService');
  const project = yield call(projectService.getOpenedProject);
  const properties = yield call(projectService.getProperties);
  const propertiesMap = _.keyBy(properties, 'name');
  const subFolderPropertyLabel = propertiesMap[subfolderPropertyName]?.label;

  const exportedProperties = _.filter(
    properties,
    (p) => p.importable || ['displayname'].includes(p.name)
  );

  const columns =
    documentFiles.length > 0
      ? ['filename', subFolderPropertyLabel]
      : [subFolderPropertyLabel];

  const parsedResources = parseFilesToResources(documentFiles);

  const objectUrl = yield call(
    generateExport,
    exportedProperties,
    columns,
    parsedResources
  );

  const fileName = `${
    project?.name
  }_${DateTime.now().toSQLDate()}_import_template.xlsx`;

  yield complete(action, { objectUrl, fileName });
}

function* importDocumentsExcel(
  folderId,
  documentFiles,
  templateData,
  duplicates,
  options
) {
  const {
    subfolderType,
    subfolderPropertyName,
    resourceId,
    createStubs,
    fileNameResolve,
    multiContent,
    skipTemplate,
  } = options;

  let validDocumentFiles = [];
  let emptyDocumentFiles = [];
  let oversizedDocumentFiles = [];
  documentFiles.forEach((f) => {
    if (!f.size) {
      emptyDocumentFiles.push(f);
      return;
    }
    if (f.size > uploadMaxFileSize) {
      oversizedDocumentFiles.push(f);
      return;
    }

    validDocumentFiles.push(f);
  });

  const data = skipTemplate
    ? parseFilesToResources(validDocumentFiles)
    : templateData.filter(
        (tD) =>
          !emptyDocumentFiles.some(
            (f) => f.name === tD.filename && f.path === tD.Path
          ) &&
          !oversizedDocumentFiles.some(
            (f) => f.name === tD.filename && f.path === tD.Path
          )
      );

  console.log(
    'IMPORTER::importDocumentsExcel',
    folderId,
    validDocumentFiles,
    emptyDocumentFiles,
    oversizedDocumentFiles,
    data,
    templateData,
    duplicates,
    options
  );

  const projectService = yield getContext('projectService');
  const properties = yield call(projectService.getProperties);

  const subfolders = yield call(
    fetchSubfolders,
    folderId,
    subfolderPropertyName,
    subfolderType
  );

  while (true) {
    const columnMapping = yield select(getImportColumnMapping);
    const valueMapping = yield select(getImportValueMapping);

    const { schema, issues: columsIssues } = yield call(
      generateDataSchema,
      data,
      // this could be put into generateDataSchema
      _.chain(properties).concat({ name: '_path', label: 'Path' }).value(),
      columnMapping,
      valueMapping
    );

    const filteredData = _.chain(data)
      .filter((r) => r.filename != null || createStubs || fileNameResolve)
      .value();

    const translatedData = translatePropertyLabels(
      filteredData,
      schema,
      columnMapping
    );

    const { resources, issues } = parseData(translatedData, schema);
    let groupedIssues = groupIssues(issues, schema);

    const fileMatchingOptions = {
      subfolderPropertyName,
      subfolders,
      createStubs,
      fileNameResolve,
      multiContent,
    };

    const { importData, issues: fileMatchIssues } = yield call(
      matchFilesToResources,
      resources,
      validDocumentFiles,
      fileMatchingOptions
    );

    let existingDocIssues = [];
    let duplicateFilesIssues = [];
    let fileMissingInTemplateIssues = [];
    let emptyFileIssues = [];
    let oversizedFileIssues = [];
    if (validDocumentFiles.length) {
      const resourcesMap = _.keyBy(
        resources,
        (item) => `${item['filename']}_${item['_path'] ?? ''}`
      );
      existingDocIssues =
        duplicates.existingDocs?.map((ed) => {
          const res = resourcesMap[`${ed.name}_${ed.path}`];
          const newIssue = {
            rows: [parseInt(res?._rowid, 10)],
            type: importIssueType.FILE_CONTENT_EXISTS,
            fileName: ed.name,
            filePath: ed.path,
            value: ed.matchedName ?? ed.name,
            resolvable: true,
          };

          newIssue.contentHash = generateStringHash(JSON.stringify(newIssue));
          return newIssue;
        }) || [];

      duplicateFilesIssues = _.flatMap(
        duplicates.duplicateContentMap,
        (sameHash) =>
          _.map(sameHash, (d) => {
            const res = sameHash.map((sh) => {
              const r = resourcesMap[`${sh.name}_${sh.path}`];
              return parseInt(r?._rowid, 10);
            });

            const newIssue = {
              rows: res,
              type: importIssueType.FILE_CONTENT_DUPLICATE,
              fileName: d.name,
              filePath: d.path,
              value: d.hash,
              resolvable: true,
            };

            newIssue.contentHash = generateStringHash(JSON.stringify(newIssue));
            return newIssue;
          })
      );

      fileMissingInTemplateIssues = _.chain(validDocumentFiles)
        .filter((f) => {
          const fileName = f.name;
          const filePath = f.path;
          return !resourcesMap[`${fileName}_${filePath}`];
        })
        .map((f) => {
          const newIssue = {
            rows: [],
            type: importIssueType.FILE_MISSING_TEMPLATE,
            fileName: f.name,
            filePath: f.path,
            uniqueFileLabel: generateUniqueFileLabel(f.path, f.name),
          };
          newIssue.contentHash = generateStringHash(JSON.stringify(newIssue));
          return newIssue;
        })
        .value();
    }

    emptyFileIssues = emptyDocumentFiles.map((f) => {
      const newIssue = {
        rows: [],
        type: importIssueType.FILE_EMPTY,
        fileName: f.name,
        filePath: f.path,
        uniqueFileLabel: generateUniqueFileLabel(f.path, f.name),
      };
      newIssue.contentHash = generateStringHash(JSON.stringify(newIssue));
      return newIssue;
    });

    oversizedFileIssues = oversizedDocumentFiles.map((f) => {
      const newIssue = {
        rows: [],
        type: importIssueType.FILE_OVERSIZED,
        fileName: f.name,
        filePath: f.path,
        fileSize: getFileSize(f.size).prettySize,
        maxSize: uploadMaxFileSizePretty,
        uniqueFileLabel: generateUniqueFileLabel(f.path, f.name),
      };
      newIssue.contentHash = generateStringHash(JSON.stringify(newIssue));
      return newIssue;
    });

    groupedIssues = [
      ...groupedIssues,
      ...fileMatchIssues,
      ...columsIssues,
      ...existingDocIssues,
      ...duplicateFilesIssues,
      ...fileMissingInTemplateIssues,
      ...emptyFileIssues,
      ...oversizedFileIssues,
    ];

    const uploadFiles = _.chain(importData)
      .filter((item) => Array.isArray(item.files) && item.files.length > 0)
      .flatMap((item) =>
        item.files.map((itemFile) => ({
          name: itemFile.name,
          path: itemFile.file.path ?? '',
          size: itemFile.file.size,
          confidential: false,
          fileIdentifier: getFileIdentifier(itemFile.file),
        }))
      )
      .value();

    console.log(
      'IMPORTER::before_setData',
      importData,
      uploadFiles,
      resources,
      groupedIssues
    );

    yield put(setUploadFiles(uploadFiles));

    yield put(setImportData(resources, groupedIssues));

    const action = yield take([
      actions.repositoryImport.EXECUTE_IMPORT,
      actions.repositoryImport.RELOAD_IMPORT,
    ]);

    const { type } = action;

    if (type === actions.repositoryImport.EXECUTE_IMPORT) {
      yield put(setUploadLoading(true, 'Importing documents into repository'));

      const projectService = yield getContext('projectService');
      const propertiesMap = yield call(projectService.getProperties);
      const hasImportableAnnexName = _.some(
        properties,
        (p) => p.name === 'AnnexName' && p.importable
      );

      const getDestinationFolder = (item) =>
        resourceId || subfolderType == null || item.folderId == null
          ? folderId
          : item.folderId;

      const { stubs, documents } = _.groupBy(importData, (i) =>
        i.files.length === 0 ? 'stubs' : 'documents'
      );

      let stubIds = [];
      if (Array.isArray(stubs) && stubs.length > 0) {
        try {
          const docareaService = yield getContext('docareaService');
          const valuesetsMap = yield select(docareaService.getValuesetsMap);
          const correctedStubs = stubs.map((stub) =>
            resourceToGateway(
              { ...stub.resource, folderId: stub.folderId },
              propertiesMap,
              valuesetsMap
            )
          );

          stubIds = yield callApi(
            repositoryApi.importResources,
            folderId,
            correctedStubs,
            2
          );
        } catch (err) {
          console.error(err);
        }
      }

      const filesMeta = yield select((state) => state.upload.files);
      const filesMetaMap = _.keyBy(
        filesMeta,
        (item) => item.name + (item.path ?? '')
      );

      const importSagas = _.map(documents, (item) =>
        call(function* () {
          const destinationFolderId = getDestinationFolder(item);

          const fileMetaKey =
            item.resource?.filename + (item.resource?._path ?? '');
          const fileMeta = filesMetaMap[fileMetaKey] || {};

          const extendedResource = {
            ...item.resource,
            private: fileMeta.confidential,
          };
          if (hasImportableAnnexName) {
            extendedResource.AnnexName = item.resource?._path ?? '';
          }
          const result = yield call(
            importDocument,
            destinationFolderId,
            extendedResource,
            item.files
          );

          yield put(addUploadedDocumentId(result.resourceId));
          yield call(updateDocumentsFilename, destinationFolderId, [result.resourceId]);
          return result;
        })
      );

      const results = yield callBatch(5, importSagas);

      yield put(notify('Import finished!', 'success'));

      yield complete(action);
      yield put(setUploadLoading(false));

      const documentIds = _.compact(_.map(results, 'resourceId'));
      if (documentIds.length + stubIds.length > 0) {
        yield put(
          selectDocumentsIdentifiers(
            _.concat(documentIds, stubIds).map((id) => {
              const { identifier } = parseResourceId(id);
              return identifier;
            })
          )
        );
      }

      yield put(setImportComplete(results));

      break;
    }
  }
}

export default function* importSaga(folderId) {
  yield takeLeading(
    actions.repositoryImport.EXPORT_FOLDERS_TEMPLATE,
    exportFoldersTemplate,
    folderId
  );
  yield takeLatest(
    actions.repositoryImport.IMPORT_FOLDERS_EXCEL,
    importFoldersExcel,
    folderId
  );

  yield fork(function* () {
    let files = [];

    while (true) {
      const action = yield take([
        actions.repositoryImport.EXPORT_DOCUMENTS_TEMPLATE,
        actions.repositoryImport.ADD_FILES_TO_IMPORT,
        actions.repositoryImport.UNZIP_FILES_TO_IMPORT,
        actions.repositoryImport.CLEAN_IMPORT,
        actions.repositoryImport.IMPORT_DOCUMENTS_EXCEL,
        actions.repositoryImport.REMOVE_FILES_FROM_IMPORT,
        actions.repositoryImport.DOWNLOAD_LOG,
      ]);
      const { type } = action;

      if (type === actions.repositoryImport.ADD_FILES_TO_IMPORT) {
        console.log('IMPORTER::ADD_FILES_TO_IMPORT', files);
        const parsedFiles = action.files.map((f) => {
          f.path = generateProperFilePath(f.path ?? f.webkitRelativePath);
          return f;
        });
        files = parsedFiles || [];
      } else if (type === actions.repositoryImport.UNZIP_FILES_TO_IMPORT) {
        const parsedFiles = action.files.map((f) => {
          f.path = generateProperFilePath(f.path ?? f.webkitRelativePath);
          return f;
        });
        files = parsedFiles || [];
      } else if (type === actions.repositoryImport.IMPORT_DOCUMENTS_EXCEL) {
        const { files: templateFiles, options } = action.payload;

        yield complete(action);

        let templateData = yield all(
          Array.from(templateFiles).map((file) => call(parseFile, file))
        );
        templateData = _.flatMap(templateData, (x) => x).map((item) => {
          return _.mapKeys(item, (v, key) => key.replace(/(\s?\*)$/g, ''));
        });

        // remove files without template equivalent
        const templateFilteredFiles = templateData.length
          ? files.filter((f) => {
              const fileName = f.name;
              const filePath = f.path;
              return (
                f.size &&
                f.size < uploadMaxFileSize && // 10GB
                templateData.some(
                  (td) => td.filename === fileName && td.Path === filePath
                )
              );
            })
          : files.filter((f) => f.size && f.size < uploadMaxFileSize);

        let duplicates = {};
        if (options.shouldCheckDuplicates) {
          const currentFileHashMap = yield select(
            (state) => state.upload.fileHashMap
          );

          const hashes = yield call(
            createContentHashSagas,
            templateFilteredFiles,
            function* (generatedHashCount) {
              yield put(
                setUploadCheckLoading(true, {
                  loadingStats: {
                    fileContentHashGeneratedCount: generatedHashCount,
                    totalFilesCount: templateFilteredFiles.length,
                  },
                })
              );
            }
          );

          for (const h of hashes) {
            const { fileIdentifier } = h;
            currentFileHashMap[fileIdentifier] = h;
            yield put(updateFileHash(fileIdentifier, h));
          }
          duplicates = yield call(duplicatesCheckerSaga, currentFileHashMap);
        }

        while (true) {
          const importDocumentTask = yield fork(
            importDocumentsExcel,
            folderId,
            files,
            templateData,
            duplicates,
            options
          );
          const { removeFiles, unzipFiles, downloadLog } = yield race({
            removeFiles: take(
              actions.repositoryImport.REMOVE_FILES_FROM_IMPORT
            ),
            cancelImport: take(actions.repositoryImport.CANCEL_IMPORT),
            unzipFiles: take(actions.repositoryImport.UNZIP_FILES_TO_IMPORT),
            downloadLog: take(actions.repositoryImport.DOWNLOAD_LOG),
            result: join(importDocumentTask),
          });

          yield cancel(importDocumentTask);

          if (removeFiles) {
            const { filesToRemove, callback } = removeFiles.payload;

            for (let fileToRemove of filesToRemove) {
              const { fileName: removeFileName, filePath: removeFilePath } =
                fileToRemove;

              // remove files
              files = files.filter((f) => {
                const isFile = !(
                  f.name === removeFileName && f.path === removeFilePath
                );
                return isFile;
              });

              const currentSchema = yield select(getCurrentSchema);
              const schemaPathLabel = currentSchema['_path']?.label || '';

              // remove corresponding template data
              templateData = templateData.filter(
                (td) =>
                  !(
                    td.filename === removeFileName &&
                    td[schemaPathLabel] === removeFilePath
                  )
              );

              // remove corresponding existing docs from duplicates
              const existingDocs = duplicates.existingDocs || [];
              duplicates.existingDocs = existingDocs.filter(
                (ed) =>
                  !(ed.name === removeFileName && ed.path === removeFilePath)
              );

              // remove corresponding content duplicates from duplicates (remove hash if it's the last file with that hash)
              const duplicateContentMap = duplicates.duplicateContentMap || {};
              duplicates.duplicateContentMap = _.chain(duplicateContentMap)
                .mapValues((sameHash) =>
                  sameHash.filter(
                    (d) =>
                      !(d.name === removeFileName && d.path === removeFilePath)
                  )
                )
                .pickBy((v) => v.length > 1)
                .value();

              callback({ fileName: removeFileName, filePath: removeFilePath });
            }
          } else if (unzipFiles) {
            yield put(setIsUnzipping(true));
            files = yield call(flatMapArchives, files);
            files = _.uniqBy(files, 'path');
            yield put(setIsUnzipping(false));
          } else if (downloadLog) {
            const log = yield select((state) => state.import.log);
            yield call(exportData, log, importActivity, 'import_log');
          } else {
            files = [];
            break;
          }
        }

        yield takeLeading(
          actions.repositoryImport.GENERATE_EXCEL_STATS_REPORT,
          function* () {
            const stats = yield select(getImportStats);
            const log = yield select((state) => state.import.log);
            const workbook = utils.book_new();
            const statsDataSheet = yield call(
              createDataSheet,
              stats,
              uploadStatistic
            );
            const logDataSheet = yield call(
              createDataSheet,
              log,
              importActivity
            );

            utils.book_append_sheet(
              workbook,
              logDataSheet,
              'import_activity_log'
            );
            utils.book_append_sheet(
              workbook,
              statsDataSheet,
              'import_upload_report'
            );
            const workbookName = `import_info_${DateTime.now().toSQLDate()}.xlsx`;
            writeFile(workbook, workbookName);
          }
        );
      } else if (type === actions.repositoryImport.EXPORT_DOCUMENTS_TEMPLATE) {
        yield call(exportDocumentsTemplate, folderId, files, action);
      } else if (type === actions.repositoryImport.CLEAN_IMPORT) {
        files = [];
      }
    }
  });
}

