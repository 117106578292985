import './global-polyfill';
import './Assets/scss/main.scss';

import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './Reducers';
import indexSaga from './Sagas';
import {docareaService, projectService, getI18n, initializeCosmosCoreConfig} from 'cosmos-core';
import './globals';
import localPropertiesService from './Services/localPropertiesService';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createRoot } from 'react-dom/client';

import * as repositoryActions from './Actions/repository';
import { I18nextProvider } from 'react-i18next';

initializeCosmosCoreConfig({
  gatewayUrl: import.meta.env.VITE_APP_GATEWAY_URL,
  nscaleBaseUrl: import.meta.env.VITE_APP_API_BASE_URL,
  configProviderUrl: import.meta.env.VITE_APP_CONFIG_PROVIDER_URL,
  enableI18n: import.meta.env.VITE_APP_ENABLE_I18N === 'true',
  enableRequestCaching: import.meta.env.VITE_APP_ENABLE_REQUEST_CACHING === 'true',
  adalClientId: import.meta.env.VITE_APP_ADAL_CLIENT_ID,
  msalRedirectUri: import.meta.env.VITE_APP_MSAL_REDIRECT_URI,
  enableDuplicateMarker: import.meta.env.VITE_APP_ENABLE_DUPLICATE_MARKER === 'true',
  enableDatepickerTyping: import.meta.env.VITE_APP_ENABLE_DATEPICKER_TYPING === 'true',
});

const enableMocking = async () => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  const { worker } = await import('./Mocks/browser');

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
};

const sagaMiddleware = createSagaMiddleware({
  context: {
    docareaService,
    projectService,
    localPropertiesService,
  },
});

const sagaCompleteMiddleware = () => (next) => (action) => {
  return new Promise((resolve) => {
    next({ ...action, onComplete: (...args) => resolve(...args) });
  });
};

const composeEnhancers = composeWithDevTools({
  name: 'Cosmos',
  actionCreators: repositoryActions,
  trace: true,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaCompleteMiddleware, sagaMiddleware))
);
sagaMiddleware.run(indexSaga);

const container = document.getElementById('root');

enableMocking().then(() => {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <I18nextProvider i18n={getI18n()}>
          <App />
        </I18nextProvider>
      </Provider>
    </React.StrictMode>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
