export const REQUEST_DOCUMENTS = 'repository/requestDocuments';
export const REFRESH_REPOSITORY_SEARCH = 'repository/refreshSearch';
export const SET_DOCUMENTS = 'repository/setDocuments';
export const SET_REPOSITORY_OPTIONS = 'repository/setOptions';
export const UPDATE_FILTER = 'repository/updateFilter';
export const SET_FILTER = 'repository/setFilter';
export const CLEAR_FILTER = 'repository/clearFilter';
export const CLEAR_SEARCH_QUERY = 'repository/clearSearchQuery';
export const UPDATE_SEARCH_QUERY = 'repository/updateSearchQuery';
export const SET_ORDERBY = 'repository/setOrderBy';
export const SET_PAGINATOR_PAGE = 'repository/setPaginatorPage';
export const CREATE_FOLDER_DUPLICATE = 'repository/createFolderDuplicate';
export const UPDATE_SELECTED_FOLDER_MULTI_ATTRIBUTE =
  'repository/updateSelectedFolderMultiAttribute';
export const CREATE_DOCUMENT_DUPLICATE = 'repository/createDocumentDuplicate';
export const HANDLE_RESOURCE_UPDATE_EVENT =
  'repository/handleResourceUpdateEvent';

export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS';
export const SET_REPOSITORY_LOADING = 'SET_REPOSITORY_LOADING';
export const CLEAR_REPOSITORY_DATA = 'CLEAR_REPOSITORY_DATA';
export const SET_PAGINATOR_PAGE_COUNT = 'SET_PAGINATOR_PAGE_COUNT';
export const SET_FOLDERS = 'SET_FOLDERS';
export const SET_FOLDER_ID = 'SET_FOLDER_ID';
export const SET_FOLDER = 'SET_FOLDER';
export const RESET_ORDERBY = 'RESET_ORDERBY';
export const CLEAR_REPOSITORY_QUERY = 'CLEAR_REPOSITORY_QUERY';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const SET_DOCUMENT_PRESETS = 'SET_DOCUMENT_PRESETS';
export const DELETE_DOCUMENTS = 'DELETE_DOCUMENTS';
export const DELETE_LINKED_DOCUMENTS = 'DELETE_LINKED_DOCUMENTS';
export const EXPORT_LINKED_DOCUMENTS = 'DOWNLOAD_LINKED_DOCUMENTS';
export const DELETE_VERSION = 'DELETE_VERSION';
export const LINK_DOCUMENTS = 'LINK_DOCUMENTS';
export const UNLINK_DOCUMENTS = 'UNLINK_DOCUMENTS';
export const SELECT_DOCUMENTS = 'SELECT_DOCUMENTS';
export const SELECT_DOCUMENTS_IDENTIFIERS = 'SELECT_DOCUMENTS_IDENTIFIERS';
export const FETCH_DOCUMENTS_BY_IDENTIFIERS = 'FETCH_DOCUMENTS_BY_IDENTIFIERS';
export const SET_SELECTED_DOCUMENTS = 'SET_SELECTED_DOCUMENTS';
export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';
export const TOGGLE_VALIDATION_ERROR = 'TOGGLE_VALIDATION_ERROR';
export const UPDATE_SELECTED_DOCUMENT = 'UPDATE_SELECTED_DOCUMENT';
export const CLEAR_SELECTION = 'CLEAR_SELECTION';
export const PROJECT_SELECTION_NEEDED = 'PROJECT_SELECTION_NEEDED';
export const SET_PROXY_FOLDER_ID = 'SET_PROXY_FOLDER_ID';
export const CREATE_SUBFOLDER = 'CREATE_SUBFOLDER';
export const CREATE_SIMPLE_SUBFOLDER = 'CREATE_SIMPLE_SUBFOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const FETCH_LINKED_DOCUMENTS = 'FETCH_LINKED_DOCUMENTS';
export const FETCH_CHILDREN_DOCUMENTS = 'FETCH_CHILDREN_DOCUMENTS';
export const ATTACH_LINKED_DOCUMENTS = 'ATTACH_LINKED_DOCUMENTS';
export const SET_REPOSITORY_READY = 'SET_REPOSITORY_READY';
export const FETCH_SEARCH_SUGGESTIONS = 'FETCH_SEARCH_SUGGESTIONS';
export const EXPORT_FOLDER_EXCEL = 'EXPORT_FOLDER_EXCEL';
export const EXPORT_DOCUMENTS_EXCEL = 'EXPORT_DOCUMENTS_EXCEL';
export const EXPORT_URLS_EXCEL = 'EXPORT_URLS_EXCEL';
export const EXPORT_DOCUMENTS_ZIP = 'EXPORT_DOCUMENTS_ZIP';
export const SET_BULK_UPDATE_PROGRESS = 'SET_BULK_UPDATE_PROGRESS';
export const PRESERVE_SELECTED_DOCUMENTS = 'PRESERVE_SELECTED_DOCUMENTS';
export const CALCULATE_PRESET = 'CALCULATE_PRESET';
export const UPDATE_PRESET = 'UPDATE_PRESET';
export const SET_DOCUMENT_AS_PRESET = 'SET_DOCUMENT_AS_PRESET';
export const FETCH_DELETED_DOCUMENTS = 'FETCH_DELETED_DOCUMENTS';
export const UNDELETE_DOCUMENTS = 'UNDELETE_DOCUMENTS';
export const IMPORT_FILES = 'IMPORT_FILES';
export const OPEN_FOLDER = 'OPEN_FOLDER';
export const CLOSE_FOLDER = 'CLOSE_FOLDER';
export const SET_SELECTED_FOLDER = 'SET_SELECTED_FOLDER';
export const UPDATE_SELECTED_FOLDER = 'UPDATE_SELECTED_FOLDER';
export const PRESERVE_SELECTED_FOLDER = 'PRESERVE_SELECTED_FOLDER';
export const FETCH_SUBFOLDER_DOCUMENTS = 'FETCH_SUBFOLDER_DOCUMENTS';
export const INITIATE_FOLDER_OF_PRESET = 'INITIATE_FOLDER_OF_PRESET';
export const FETCH_FOLDERS = 'FETCH_FOLDERS';
export const FETCH_AGGREGATE_LATEST = 'FETCH_AGGREGATE_LATEST';
export const CANCEL_FOLDER_WIZARD = 'CANCEL_FOLDER_WIZARD';
export const SCOPE_SELECTED_DOCUMENT = 'SCOPE_SELECTED_DOCUMENT';
export const VIRTUAL_MOVE_DOCUMENTS = 'VIRTUAL_MOVE_DOCUMENTS';
export const CREATE_DOCUMENT_REFERENCE = 'CREATE_DOCUMENT_REFERENCE';
export const SAVE_SUBFOLDER_DRAFT = 'SAVE_SUBFOLDER_DRAFT';
export const COPY_TO_CLIPBOARD = 'COPY_TO_CLIPBOARD';
export const HIGHLIGHT_DOCS = 'HIGHLIGHT_DOCS';
export const PASTE_CLIPBOARD = 'PASTE_CLIPBOARD';
export const CLEAR_CLIPBOARD = 'CLEAR_CLIPBOARD';
export const SET_THUMBNAIL_LOADING = 'SET_THUMBNAIL_LOADING';
export const SET_THUMBNAIL = 'SET_THUMBNAIL';
export const PROJECT_QUERY_RUN = 'PROJECT_QUERY_RUN';
export const SET_SEARCH_PROPERTIES = 'SET_SEARCH_PROPERTIES';
export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS';
export const SET_FOLDER_TREE_STATE = 'SET_FOLDER_TREE_STATE';
export const UPDATE_FOLDER_TREE_STATE_OPTION =
  'UPDATE_FOLDER_TREE_STATE_OPTION';
export const UPDATE_FOLDER_TREE_STATE_OPTIONS =
  'UPDATE_FOLDER_TREE_STATE_OPTIONS';
export const UNLOCK_DOCUMENTS = 'UNLOCK_DOCUMENTS';
export const GET_FOLDERS_COUNT = 'GET_FOLDERS_COUNT';
export const SET_FOLDERS_COUNT = 'SET_FOLDERS_COUNT';
export const SET_ADVANCED_SEARCH = 'SET_ADVANCED_SEARCH';

