import { createElement as h } from 'react';
import {
  faBuilding,
  faComment,
  faComments,
  faCompass,
} from '@fortawesome/free-regular-svg-icons';
import { faBug, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  property,
  systemProperty,
  valuesetSource,
} from 'cosmos-config/generator';
import { DateTime } from 'luxon';

export default [
  systemProperty('id'),
  systemProperty('Receiver', 'receiver'),
  property('Type', 'type')
    .selectableAttribute('type', 'type', valuesetSource.STATIC_VALUESET)
    .valueset([
      { label: 'Contact', value: 'CONTACT' },
      { label: 'Module', value: 'PROJECT' },
      { label: 'Global', value: 'GLOBAL' },
      { label: 'Report', value: 'REPORT' },
      { label: 'Peer to Peer', value: 'P2P' },
    ])
    .excludeForm()
    .tableCell((v) => {
      switch (v) {
        case 'CONTACT':
          return <FontAwesomeIcon icon={faComment} className="text-info" />;
        case 'REPORT':
          return <FontAwesomeIcon icon={faBug} className="text-danger" />;
        case 'PROJECT':
          return (
            <div>
              <FontAwesomeIcon
                icon={faBuilding}
                className="text-warning mr-2"
              />
              Module
            </div>
          );
        case 'GLOBAL':
          return (
            <div>
              <FontAwesomeIcon icon={faCompass} className="text-success mr-2" />
              Global
            </div>
          );
        case 'P2P':
          return <FontAwesomeIcon icon={faComments} />;
        default:
          return <FontAwesomeIcon icon={faMicrophone} />;
      }
    }),
  property('Message', 'text').richTextArea(1000).translate('message.message'),
  systemProperty('Sender', 'principalId'),
  property('Created At').dateAttribute('createdAt').excludeForm(),
  property('Valid Until')
    .dateAttribute('validUntil')
    .excludeTime()
    .translate('message.valid_until')
    .onUpdate((value) => {
      if (value != null) {
        return DateTime.fromMillis(value).endOf('day').toMillis();
      }
      return value;
    }),
];
