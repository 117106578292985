export const LOCAL_STORAGE_TEMPORARY_BUCKET = 'temporary_bucket';
export const FOLDER_TYPE_TEMPORARY_BUCKET = 'temporary_bucket';

export const LOCAL_STORAGE_SHOW_EMPTY = 'show_empty_folders';
export const LOCAL_STORAGE_PREVIOUS_SEARCHES = 'previous_searches';
export const LOCAL_STORAGE_FAV_DOCS_RESET_KEY =
  'favourite-documents-reset-workaround';

export const PASSO_MODULE_CODE = 'PASSO';

