/* eslint-disable func-names */
import { DateTime } from 'luxon';
import { channel } from 'redux-saga';
import { call, cancel, delay, fork, put, take } from 'redux-saga/effects';
import { notify, storeRequestTime } from '../../Actions/ui';

const REQUEST_START = 'REQUEST_START';
const REQUEST_FINISH = 'REQUEST_FINISH';
const REQUEST_TOO_LONG = 'REQUEST_TOO_LONG';

function* requestTimer(chan) {
  while (true) {
    const { type } = yield take(chan);

    if (type === REQUEST_START) {
      const startMillis = DateTime.now().toMillis();

      const notificationTimerTask = yield fork(function* () {
        while (true) {
          yield delay(20000);
          chan.put({ type: REQUEST_TOO_LONG });
          const time = DateTime.now().toMillis() - startMillis;
          yield put(storeRequestTime(time));
        }
      });

      while (true) {
        const { type: secondType } = yield take(chan);

        if (secondType === REQUEST_FINISH) {
          const time = DateTime.now().toMillis() - startMillis;
          yield put(storeRequestTime(time));
          console.info(
            `${DateTime.now().toLocaleString(
              DateTime.DATETIME_SHORT
            )} : Request fulfilled in ${time}ms.`
          );
          yield cancel(notificationTimerTask);
        }

        if (secondType === REQUEST_TOO_LONG) {
          yield put(
            notify(
              'Request is taking longer then usually. There might be some connection issues. If nothing happens in short time after this message reload the page using reload button or F5 key, please.',
              'warning'
            )
          );
        }
      }
    }
  }
}

export default function* callApi(fn, ...args) {
  const timerChannel = yield channel();
  const requestTimerTask = yield fork(requestTimer, timerChannel);

  // let retryCounter = 0;
  while (true) {
    try {
      timerChannel.put({ type: REQUEST_START });
      return yield call(fn, ...args);
    } catch (err) {
      console.error(err);
      throw err;
      // } catch (err) {
      //   if (err.code === 401) {
      //     const authToken = localStorage.getItem('authToken');
      //     const domain = localStorage.getItem('domain');
      //     if (String(authToken).includes('Bearer') && retryCounter === 0) {
      //       retryCounter += 1;
      //       if (domain === 'Users') {
      //         yield put(refreshADFS());
      //       } else if (domain === 'ExternalUsers') {
      //         yield put(loginExternalADFS());
      //       }
      //       const { type } = yield take([LOGIN_ADFS_SUCCESS, LOGIN_ADFS_ERROR]);
      //       if (type !== LOGIN_ADFS_SUCCESS) {
      //         if (domain === 'ExternalUsers') {
      //           yield put(logout());
      //           throw err;
      //         }
      //         yield put(refreshADFSAttempt());
      //         const { type: adfsAttemptType } = yield take([
      //           LOGIN_ADFS_SUCCESS,
      //           LOGIN_ADFS_ERROR,
      //         ]);
      //         if (adfsAttemptType !== LOGIN_ADFS_SUCCESS) {
      //           yield put(logout());
      //           throw err;
      //         }
      //       }
      //     } else {
      //       yield put(logout());
      //       throw err;
      //     }
      //   } else {
      //     throw err;
      //   }
    } finally {
      timerChannel.put({ type: REQUEST_FINISH });
      yield cancel(requestTimerTask);
    }
  }
}
