import * as actions from './types';

/**
 * Project action generators.
 * @module actions/project
 * @category Actions
 */

/**
 * Action for setting project loading status
 * @function
 * @param {boolean} loading - status flag of the project loading process
 * @param {string} message - message text
 */
export const setLoadingProject = (loading, message) => ({
  type: actions.document.SET_LOADING_PROJECT,
  loading,
  message,
});

/**
 * Action to open project.
 * @function
 * @param {} projectCode -
 * @param {} resourceId -
 * @param {function} callback - action results callback
 */
export const openProject = (projectCode, resourceId, callback = () => {}) => ({
  type: actions.project.OPEN_PROJECT,
  projectCode,
  resourceId,
  callback,
});

/**
 * Action to fetch project properties.
 * @function
 */
export const fetchProjectProperties = () => ({
  type: actions.project.FETCH_PROJECT_PROPERTIES,
});

/**
 * Action to set project properties.
 * @function
 * @param {} properties -
 */
export const setProjectProperties = (properties) => ({
  type: actions.project.SET_PROJECT_PROPERTIES,
  properties,
});

/**
 * Action for fetching project messages.
 * @function
 */
export const fetchProjectMessages = () => ({
  type: actions.message.FETCH_PROJECT_MESSAGES,
});

/**
 * Action for setting project messages.
 * @function
 * @param {} messages -
 */
export const setProjectMessages = (messages) => ({
  type: actions.message.SET_PROJECT_MESSAGES,
  messages,
});

/**
 * Action for deleting project message.
 * @function
 * @param {string[]} messageIds -
 * @param {function} callback - action results callback
 */
export const deleteProjectMessage = (messageIds, callback = () => {}) => ({
  type: actions.message.DELETE_PROJECT_MESSAGE,
  messageIds,
  callback,
});

/**
 * Action for creating project message.
 * @function
 * @param {string} message - message text
 * @param {function} callback - action results callback
 */
export const createProjectMessage = (message, callback = () => {}) => ({
  type: actions.message.CREATE_PROJECT_MESSAGE,
  message,
  callback,
});

/**
 * Action for updating project message.
 * @function
 * @param {string} messageId - id of the message
 * @param {string} message - message text
 * @param {function} callback - action results callback
 */
export const updateProjectMessage = (
  messageId,
  message,
  callback = () => {}
) => ({
  type: actions.message.UPDATE_PROJECT_MESSAGE,
  messageId,
  message,
  callback,
});

/**
 * Action for creating project contact message.
 * @function
 * @param {string} text - text of the contact message
 * @param {function} callback - action results callback
 */
export const createProjectContactMessage = (text, callback = () => {}) => ({
  type: actions.message.CREATE_PROJECT_CONTACT_MESSAGE,
  message: {
    text,
  },
  callback,
});

/**
 * Action for fetching widgets.
 * @function
 * @param {function} callback - action results callback
 */
export const fetchWidgets = (callback = () => {}) => ({
  type: actions.project.FETCH_WIDGETS,
  callback,
});

/**
 * Action tfor setting widgets.
 * @function
 * @param {} widgets -
 */
export const setWidgets = (widgets) => ({
  type: actions.project.SET_WIDGETS,
  widgets,
});

/**
 * Actionfor saving all widgets.
 * @function
 * @param {} widgets -
 * @param {function} callback - action results callback
 */
export const saveAllWidgets = (widgets, callback = () => {}) => ({
  type: actions.project.SAVE_ALL_WIDGETS,
  widgets,
  callback,
});

/**
 * Action to restore default widgets.
 * @function
 * @param {function} callback - action results callback
 */
export const restoreDefaultWidgets = (callback = () => {}) => ({
  type: actions.project.RESTORE_DEFAULT_WIDGETS,
  callback,
});

/**
 * Action for fetching project queries.
 * @function
 */
export const fetchProjectQueries = () => ({
  type: actions.project.PROJECT_QUERIES_FETCH,
});

/**
 * Action for setting project queries.
 * @function
 * @param {} queries -
 */
export const setProjectQueries = (queries) => ({
  type: actions.project.PROJECT_QUERIES_SET,
  queries,
});

/**
 * Action for creating project queries.
 * @function
 * @param {} repositoryQuery -
 * @param {function} callback - action results callback
 */
export const createProjectQuery = (repositoryQuery, callback = () => {}) => ({
  type: actions.project.PROJECT_QUERY_CREATE,
  repositoryQuery,
  callback,
});

/**
 * Action for updating project query.
 * @function
 * @param {} queryId - id of the query
 * @param {} projectQuery - value of the project query
 * @param {function} callback - action results callback
 */
export const updateProjectQuery = (
  queryId,
  projectQuery,
  callback = () => {}
) => ({
  type: actions.project.PROJECT_QUERY_UPDATE,
  queryId,
  projectQuery,
  callback,
});

/**
 * Action for deleting project query.
 * @function
 * @param {} queryId - id of the query
 * @param {function} callback - action results callback
 */
export const deleteProjectQuery = (queryId, callback = () => {}) => ({
  type: actions.project.PROJECT_QUERY_DELETE,
  queryId,
  callback,
});

/**
 * Action to overwrite project query.
 * @function
 * @param {} queryId - query id
 * @param {function} callback - action results callback
 */
export const overwriteProjectQuery = (queryId, callback = () => {}) => ({
  type: actions.project.PROJECT_QUERY_OVERWRITE,
  queryId,
  callback,
});
